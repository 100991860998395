// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo5.iossmlm.com/mlm_12.8/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "https://demo5.iossmlm.com/mlm_12.8/uploads/images/";
// const BASE = "https://demo5.iossmlm.com/mlm_12.8";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://checkout.demo5.iossmlm.com/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://checkout.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://checkout.demo5.iossmlm.com/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://159.89.225.72:1000/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://159.89.225.72:1000/uploads/images/";
// const BASE = "http://159.89.225.72:1000";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
//  const BASE_URL = "http://127.0.0.1:5000/api/";
// const BASE_URL = "http://162.19.139.70:60/api/";
// const BASE_URL = "http://162.19.139.70:105/api/";
//  const BASE_URL = "http://192.168.06.13:5000/api/";  
//  const BASE_URL = "http://192.168.06.13:5000/api/web/"; 
//  const BASE_URL = "http://node.demo5.iossmlm.com:5000/api/web/"; 
//  const BASE_URL = "https://api.infinitemlmsoftware.com/api/web/"; 
//  const BASE_URL = "https://app.infinitemlmsoftware.com/api/web/"; 
//  const BASE_URL = "https://user.infinitemlmsoftware.com/api/web/"; 
//  const BASE_URL = "http://192.168.06.5:350/api/web/";  
//  const BASE_URL = "http://192.168.06.13:5001/api/"; 
//  const BASE_URL = "http://192.168.9.3:5000/api/";  
// const BASE_URL = "http://127.0.0.1:5000/api/";
// const BASE_URL = "http://167.235.248.102:1008/api/";

//LIVE API URL
// const BASE_URL = "http://162.19.139.70:5000/api/";
//const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://127.0.0.1:5000/";
// const BASE = "http://162.19.139.70:5000";
// const BASE = "http://beta.infinitemlmsoftware.com:5000/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
//const DEFAULT_KEY ='14869ac2-d19b-497b-b0bb-4845393e11f9'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
//'387245a5a919ea2071cc406b10b89d4685e5cc8e'


//rd
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
//  const BASE_URL = "http://192.168.6.13:5000/api/web/"; 
// const IMG_URL = "http://192.168.6.13:5000/uploads/images/";
// const BASE = "http://192.168.6.13:5000/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'

//demo
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
//  const BASE_URL = "http://162.19.146.135:1128/api/web/"; 
// const IMG_URL = "http://162.19.146.135:1128/api/uploads/images/";
// const BASE = "http://162.19.146.135:1128/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'

//live http://162.0.228.72:5000/

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
//  const BASE_URL = "http://162.0.228.72:5000/api/web/"; 
// const IMG_URL = "http://162.0.228.72:5000/api/uploads/images/";
// const BASE = "http://162.0.228.72:5000/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'


// https://admin.tailormadeng.com/
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
 const BASE_URL = "https://admin.tailormadeng.com/api/web/"; 
const IMG_URL = "https://admin.tailormadeng.com/api/uploads/images/";
const BASE = "https://admin.tailormadeng.com/";
const LEAD_URL = "https://admin.tailormadeng.com/backoffice/api/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYSTACK_PUBLIC_KEY="pk_test_2110290043e712ba3530cf946824074c2fd50aa9"
const PAYSTACK_PUBLIC_KEY="pk_live_17459173f98ac2f1b4f09e4ae17f7c7091062e8b"


// local  Smnd
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.23.44:5000/api/web/"; 
// const IMG_URL = "http://192.168.23.44:5000/api/uploads/images/";
// const BASE = "http://192.168.23.44:5000/";
// const LEAD_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYSTACK_PUBLIC_KEY="pk_test_2110290043e712ba3530cf946824074c2fd50aa9"


export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY,
   LEAD_URL,
   PAYSTACK_PUBLIC_KEY
}


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// //const BASE_URL = "http://127.0.0.1:5000/api/";
// const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://mlmadmin.demo5.iossmlm.com";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='14869ac2-d19b-497b-b0bb-4845393e11f9'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.7.74/garage/work-copy/mlm/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://192.168.7.74/garage/work-copy/mlm/uploads/images/";
// const BASE = "http://192.168.7.74/garage/work-copy/mlm/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = 'bfd6eb01-9991-4380-88bc-c42852a8dbcc'
// //const DEFAULT_KEY = '14963'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

