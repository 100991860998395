import React, { useEffect, useState } from "react";
import style from "./Common.module.scss";
import {
  FreeJoin,
  BankTransfer,
  Ewallet,
  Epin,
  PurchaseWallet,
  PaypalPayment,
  Stripe,
  PayStack
} from "../Payments";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { useSelector } from "react-redux";

function  PaymentMethods(props) {
  console.log("props inside payment methods",props);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [checkout, setCheckout] = useState(false);
  const [state, setState] = useState({
    currentTab: 0,
  });
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentTab: props.selectedTab,
    }));
  }, [props.selectedTab]);
  //click the current tab
  const handleClick = (currentTab) => {
    props.paymentMethodChange(currentTab);

    setState((prev) => ({
      ...prev,
      currentTab: currentTab,
    }));
  };
  //create components
  const PaymentComponents = (type) => {
    switch (type) {
      case "free_purchase":
      case "freejoin":
        return <FreeJoin prevButtonClick={props.prevButtonClick} loader={props.loader}  submit={props.submit} />;
      case "banktransfer":
        return (
          <BankTransfer
            prevButtonClick={props.prevButtonClick}
            userName={props.userName}
            type={props.payment_type}
            submit ={props.submit}
            loader ={props.loader}
          />
        );
      case "ewallet":
        return (
          <Ewallet
            payment_type={props.payment_type}
            product={props.product}
            EwalletData={props.ewallet}
            prevButtonClick={props.prevButtonClick}
            submit ={props.submit}
            loader ={props.loader}
          />
        );
      case "epin":
        return (
          <Epin
            payment_type={props.payment_type}
            prevButtonClick={props.prevButtonClick}
            product={props.product}
            epin={props.epin}
            submit ={props.submit}
            username={props.userName}
            sponserUsername = {props.sponserUsername}
            loader ={props.loader}
          />
        );
      case "authorize":
        return (
          <pre className={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Authorize payment available in live mode only
          </pre>
        );
      case "blockchain":
        return (
          <pre cclassName={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Blockchain payment available in live mode only
          </pre>
        );
      case "bitgo":
        return (
          <pre className={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Bitgo payment available in live mode only
          </pre>
        );
      case "payeer":
        return (
          <pre className={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Payeer payment available in live mode only
          </pre>
        );
      case "sofort":
        return (
          <pre className={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Sofort payment available in live mode only
          </pre>
        );
      case "squareup":
        return (
          <pre className={`${
            currentTheme == "theme-dark"
              ? `${style.notreqnow2} alert alert-danger`
              : `alert alert-info ${style.notreqnow2}`
          }`}>
            {" "}
            Squareup payment available in live mode only
          </pre>
        );
        case "stripe":
          return (
          <Stripe
          prevButtonClick={props.prevButtonClick}
          amount={props.amount}
          purchaseWallet={props.ewallet}
          product={props.product}
          submit ={props.submit}
          stripeError={props.stripeError}
          loader ={props.loader}
          />
          );
      case "paystack":
          return (
            <PayStack
          email={props.email}
          prevButtonClick={props.prevButtonClick}
          amount={props.amount}
          purchaseWallet={props.ewallet}
          product={props.product}
          submit ={props.submit}
          // stripeError={props.stripeError}
          // loader ={props.loader}
          />
          );
      case "purchase_wallet":
        return (
          <PurchaseWallet
            prevButtonClick={props.prevButtonClick}
            amount={props.amount}
            purchaseWallet={props.ewallet}
            submit ={props.submit}
            loader ={props.loader}
          />
        );
      case "paypal":
        return checkout === true ? (
          <div className="payment-div">
            {console.log("props.getPaypalData",props.getPaypalData)}
            <PaypalPayment
              amount={props.amount}
              loader ={props.loader}
              submit ={props.submit}
              planId={props.planId}
               getPaypalData={props.getPaypalData}
            />
          </div>
        ) : (
          <div className="p-2">
            <pre className={`${
                currentTheme == "theme-dark"
                  ? `${style.notreqnow2} alert alert-danger`
                  : `alert alert-info ${style.notreqnow2}`
              }`}>{t("Common.clickFinish")}</pre>
            <div className={style.Buttons}>
              {props.prevButtonClick && (
                <CustomButton onClick={props.prevButtonClick} variant="success">
                  {t("profile.prev")}
                </CustomButton>
              )}
              <CustomButton
                onClick={() => {
                  setCheckout(true);
                }}
                variant="primary"
              >
                {t("Button.finish")}
              </CustomButton>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className={style.PaymentMethods}>
      <div className={style.tab}>
        {props.methods.map((button, i) => (
          <button
            key={button.code}
            className={`${style.tablinks} ${
              state.currentTab === i && style.active
            }`}
            type="button"
            onClick={() => handleClick(i)}
          >
            <h4>
              <i className={button.icon}></i>
            </h4>
            {t(`Common.${button.code}`)}
          </button>
        ))}
      </div>
      <div className={style.tabcontent}>
        {state.currentTab !== -1 && (
          <React.Fragment>
            {props.methods.length > 0 &&
              PaymentComponents(props.methods[state.currentTab]?.code)}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default PaymentMethods;
