import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomButton } from '../common';
import {useTranslation} from 'react-i18next';
function PaypalPayment(props) {
 
    const [paid, setPaid] = useState(false);
    const [error, setError] = useState(null);
    const   paypalRef =useRef();
    const {t} = useTranslation();
          //
    const passPaymentDetails = useCallback((id)=>{
      props.getPaypalData(id);
    },[props]);
    //initialise the paypal config
    useEffect(() => {
      props.planId ?
      
      window.paypal.Buttons({
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            'plan_id': props.planId // Creates the subscription
          });
        },
        onApprove: function(data, actions) {
         // alert('You have successfully created subscription ' + data.subscriptionID); // Optional message given to subscriber
        
         setPaid(true);
              passPaymentDetails({
                subscriptionID : data.subscriptionID,
                data : data
                
              });
        }
      }).render(paypalRef.current)
      
      :  window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                  {
                    description: "Your description",
                    amount: {
                      currency_code: "USD",
                      value: parseFloat(props.amount).toFixed(2),
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
               const order = await actions.order.capture();
              setPaid(true);
              passPaymentDetails({
                orderID : data.orderID,
                payerID : data.payerID
              });
            },
            onError: (err) => {
              setError(err)
            },
          })
          .render(paypalRef.current)
      }, [props.amount,passPaymentDetails]);

     const clickHandler = (e) =>{
      props.checkout && props.checkout(false)

      // if (
      //   window.location.pathname.includes("/register") ||
      //   window.location.pathname.includes("/replica_register")
      // ) {
        props.submit(e,"","payment");
      // } else {
      //   props.submit(e);
      // }
      
     }

      // If the payment has been made
    if (paid) {
      return <CustomButton variant="primary" type="submit" onClick={clickHandler}>
                  {t('Button.finish')}  
                  
              </CustomButton>;  
    }

    // If any error occurs
    if (error) {
      return <div>Error Occurred in processing payment.! Please try again.</div>;
     // return <div>Error Occurred in processing payment.! Please try again with amount</div>;
    }
    return (
        <div className="p-2">
            <div ref={paypalRef} />
        </div>
    )
}

export default PaypalPayment
